/*-------------------- Create-content --------------------*/

#create-content {
    background-color: #fff;
}

/*-------------------- Create-content-container --------------------*/

.create-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- Create-content-info --------------------*/

.create-content-info {
    text-align: center;
}

.create-content-info h2 {
    color: #002042;
    font-weight: 600;
}

.create-content-info p {
    color: #002042;
}

/*-------------------- Create-content-form --------------------*/

.create-content-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-content-form-label {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 40vw;
}

.create-content-form-label textarea {
    max-width: 40vw;
    min-width: 40vw;
    min-height: 200px;
    font-family: 'Montserrat';
    font-size: 1em;
    border: 1px solid #1F3A57;
    outline: none;
    margin-top: 10px;
}

.create-content-form span {
    font-size: 0.9em;
    font-weight: 500;
    color: #002042;
}

.create-content-form-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #1F3A57;
    padding: 10px;
    font-size: 1em;
}

/*-------------------- Btn --------------------*/

.btn-upload {
    outline: none;
    border: 1px solid #002042;
    transition: 1s;
    color: #002042;
    font-family: 'Montserrat';
    font-weight: 600;
    padding: 5px 20px; 
    border-radius: 10px;
}

.btn-upload:hover{
    background-color: #002042;
    color: #fff;
    border: 1px solid #fff;
}

.percent {
    text-align: center;
    margin: 0;
    font-size: 1.2em;
    transition: 1s;
}

/*-------------------- Dropzone --------------------*/

.dropzone-file {
    list-style: none;
    font-size: 1.2em;
    color: #002042;
    text-align: center;
}

.dropzone-info {
    font-size: 0.8em;
    margin-top: 0;
    text-align: justify;
}

.image-span {
    padding-bottom: 10px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .create-content-form-label {
        width: 80%;
        justify-content: center;
    }

    .create-content-form-label textarea {
        max-width: 98%;
        min-width: 98%;
        min-height: 100px;
    }

    .btn-upload {
        margin-top: 15px;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .create-content-form-label {
        width: 80%;
        justify-content: center;
    }

    .create-content-form-label textarea {
        max-width: 98%;
        min-width: 98%;
        min-height: 100px;
    }

    .btn-upload {
        margin-top: 15px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
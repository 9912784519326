/*-------------------- Content --------------------*/

#content {
    min-height: 100vh;
}

/*-------------------- Content-container --------------------*/

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- Content-container-info --------------------*/

.content-container-info {
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;
}

/*-------------------- Content-container-title --------------------*/

.content-container-title {
    font-family: 'Fahkwang';
    font-size: 1.8em;
    font-weight: 400;
    color: #002042;
    letter-spacing: 2px;
}

/*-------------------- Content-container-title --------------------*/

.content-container-text {
    font-size: 1.2em;
    color: #002042;
}

/*-------------------- Content-container-posts --------------------*/

.content-container-posts {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .content-container-info {
        width: 80%;
        text-align: center;
    }

    .content-container-posts {
        width: 80%;
        justify-content: center;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .content-container-info {
        width: 80%;
        text-align: center;
    }

    .content-container-posts {
        width: 80%;
        justify-content: center;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
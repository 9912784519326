/*-------------------- Header --------------------*/

header {
    width: 100%;
    background-color: transparent;
}

header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15vh;
    width: 80%;
    margin: auto;
}

header nav {
    display: flex;
}

header nav ul {
    display: flex;
}

header nav ul li {
    list-style: none;
}

/*-------------------- Navlink --------------------*/

.navlink-inverse {
    font-size: 1.2em;
    text-decoration: none;
    color: #002042;
    padding: 10px;
    margin: 0 10px;
    transition: 0.8s;
}

.navlink-inverse:hover {
    color: #7C8896;
    transition: 0.8s;
}

/*-------------------- Btn --------------------*/

.btn-logout-inverse {
    border: none;
    outline: none;
    background-color: transparent;
    color: #002042;
    font-family: 'Montserrat';
    font-size: 1.2em;
    margin: 0 10px;
    transition: 0.8s;
}

.btn-logout-inverse:hover {
    color: #7C8896;
    transition: 0.8s;
}

/*-------------------- Media Queries --------------------*/

@media (max-width: 1120px) {

    .menu-section nav {
        display: none;
    }

    .one-inverse,
    .two-inverse,
    .three-inverse {
        background-color: #000;
        height: 5px;
        width: 100%;
        margin: 6px auto;
        transition-duration: 0.3s;
    }

    .menu-toggle {
        width: 40px;
        height: 30px;
        margin-right: 20px;
    }

    /* Fullscreen */
    .menu-section.on {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #1F3A57;
        z-index: 10; /* Camada acicma, evitar o scrool dos itens abaixo */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu-section.on nav {
        display: block;
    }

    .menu-section.on .menu-toggle {
        position: absolute;
        right: 25px;
        top: 25px;
    }

    .menu-section.on .menu-toggle .one-inverse {
        transform: rotate(45deg) translate(7px, 7px);
        background-color: #000;;
    }

    .menu-section.on .menu-toggle .two-inverse {
        opacity: 0;
    }

    .menu-section.on .menu-toggle .three-inverse {
        transform: rotate(-45deg) translate(8px, -9px);
        background-color: #000;
    }

    .menu-section.on nav ul {
        text-align: center;
        display: block;
    }

    .navlink-inverse {
        transition-duration: 0.5s;
        font-size: 2.0em;
        display: block;
        color: #fff;
        padding: 1.8em;
    }

    .btn-logout-inverse {
        transition-duration: 0.5s;
        font-size: 2.0em;
        display: block;
        color: #fff;
        padding: 1.8em 4.3em;
    }
}



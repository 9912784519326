/*-------------------- About --------------------*/

#about {
    height: 100vh;
    width: 100%;
    background-image: url(../../images/Quem\ somos-G-compreto.jpg);
}

/*-------------------- About-container --------------------*/

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 60%;
}

.about-container h2 {
    text-transform: uppercase;
    font-family: 'Mansory';
    font-size: 4em;
    font-weight: 300;
    letter-spacing: 4px;
}

.about-container p {
    font-family: 'Fahkwang';
    font-size: 2em;
    margin-top: 0;
    letter-spacing: 2px;
}

/*-------------------- About2 --------------------*/c

#about2 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- About2-container --------------------*/

.about2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    text-align: center;
    font-size: 1.2em;
    color: #000;
    margin: 0 auto;
    margin-top: 30px;
}

.about2-container h2 {
    font-family: 'Fahkwang', 'Montserrat';
    font-weight: 400;
    letter-spacing: 5px;
    color: #002042;
}

.about2-container p {
    text-align: justify;
}

/*-------------------- Card-container --------------------*/

.card-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 100px auto;
}

/*-------------------- Card --------------------*/

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 450px;
    height: 850px;
    transition: 1s;
    border-radius: 10px;
}

.card:hover {
    background-color: #002042;
    transition: 1s;
    color: #fff;
}

.card img {
    height: 500px;
    padding-top: 20px;
}

.card h3 {
    font-size: 1.3em;
    font-family: 'Fahkwang', 'Montserrat';
    font-weight: 800;
    letter-spacing: 1px;
    margin-bottom: -10px;
}

.card p {
    text-align: justify;
}

/*-------------------- Email --------------------*/

.email {
    font-weight: 500;
}

/*-------------------- About-link-container --------------------*/

.about-link-container-show {
    visibility: visible;
}

.about-link-container-hide {
    visibility: hidden;
}

/*-------------------- About-link --------------------*/

.about-link {
    text-decoration: none;
    color: #002042;
    font-size: 1em;
    padding: 5px 10px;
    border: 1px solid #002042;
    background-color: #ffff;
    border-radius: 20px 5px 20px 5px;
    margin: 0px 10px;
    position: relative;
    top: 20px;
    transition: 1s;
}

.about-link:hover {
    color: #fff;
    background-color: #002042;
    border: 1px solid #fff;
    transition: 1s;
}

.about-link2 {
    text-decoration: none;
    color: #002042;
    font-size: 1em;
    padding: 5px 10px;
    border: 1px solid #002042;
    background-color: #ffff;
    border-radius: 20px 5px 20px 5px;
    margin: 0px 10px;
    transition: 1s;
}

.about-link2:hover {
    color: #fff;
    background-color: #002042;
    border: 1px solid #fff;
    transition: 1s;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .about-container {
        height: 70%;
        font-size: 1.4em;
        text-align: center;
    }

    .card-container {
        flex-wrap: wrap;
    }

    .card {
        width: 100%;
    }

    .card img {
        height: 50%;
    }

    .about-link-container-hide {
        visibility: visible;
    }

    .about-link2 {
        position: relative;
        top: 20px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .about-container {
        height: 70%;
        font-size: 1.2em;
        text-align: center;
    }

    .card-container {
        flex-wrap: wrap;
    }

    .about-link-container-hide {
        visibility: visible;
    }

    .about-link2 {
        position: relative;
        top: 10px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .about-container {
        height: 70%;
        font-size: 1.3em;
        text-align: center;
    }

    .card-container {
        flex-wrap: wrap;
    }

    .about-link-container-hide {
        visibility: visible;
    }

    .about-link2 {
        position: relative;
        top: 10px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .about-container {
        height: 70%;
        font-size: 1.3em;
        text-align: center;
    }

    .card-container {
        flex-wrap: wrap;
    }

    .about-link-container-hide {
        visibility: visible;
    }

    .about-link2 {
        position: relative;
        top: 10px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
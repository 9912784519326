/*-------------------- name-container --------------------*/

@font-face {
    font-family: 'Mansory';
    src: url('../../fonts/Mansory-Regular.woff');
}

.name-container  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2.8em;
    text-align: center;
    height: 70%;
}

.name-container h1 {
    font-family: 'Mansory', 'Montserrat';
    font-weight: normal;
    letter-spacing: 5px;
    margin-bottom: -10px;
}

.name-container p {
    font-family: 'Fahkwang', 'Montserrat';
    font-weight: 300;
    letter-spacing: 10px;
}

/*-------------------- Home --------------------*/

#home {
    background: url(../../images//imagemdefundo-GG.jpg);
    height: 100vh;
}

/*-------------------- Home 2 --------------------*/

.news {
    background-color: #fff;
    text-align: center;
    padding: 20px 0px;
}

.news h3 {
    font-family: 'Fahkwang', 'sans-serif';
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 5px;
    color: #002042;
}

/*-------------------- Home 3 --------------------*/

#home2 {
    background-color: #fff;
    text-align: center;
    padding: 20px 0px;
}

#home2 h3 {
    font-family: 'Fahkwang', 'sans-serif';
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 5px;
    color: #002042;
    margin-bottom: 50px;
}
/*-------------------- Slider --------------------*/

.slider {
    margin-bottom: 50px;
    height: 400px;
}

/*-------------------- Slider-div --------------------*/

.slider-div {
    transition: 1s;
    background-color: #002042;
    height: 400px;
}

.slider-div img {
    opacity: 0.2;
    transition: 1s;
}

/*-------------------- Slider-img --------------------*/

.slider-img {
    height: 400px;
    z-index: 0;
}

/*-------------------- Slider-title --------------------*/

.slider-title,
.slider-title-text {
    font-family: 'Fahkwang';
    font-weight: 200;
    letter-spacing: 1px;
}

.slider-title {
    font-size: 1.5em;
    color: #fff;
    z-index: 1;
    transition: 0.8s;
    font-weight: 200;
    /*background-color: rgba(0, 32, 66, 0.3);*/
    background-color: rgba(124, 136, 150, 0.4);
    padding: 10px;
    border-radius: 20px;
    display: inline;
    position: relative;
    bottom: 45px;
}

.slider-title-text {
    font-size: 1.5em;
    color: #fff;
    z-index: 1;
    position: relative;
    bottom: 400px;
    transition: 0.8s;

}


/*-------------------- Slider-text --------------------*/

.slider-text-hidden {
    z-index: 1;
    color: #002042;
    position: relative;
    bottom: 400px;
    text-align: center;
    padding: 10px;
    display: none;
    transition: 1s;
}

.slider-text-show {
    z-index: 1;
    color: #fff;
    position: relative;
    bottom: 400px;
    text-align: justify;
    padding: 20px;
    transition: 1s;
    line-height: 22px;
}

/*-------------------- Slick Arrows --------------------*/

.slick-prev {
    left: 2% !important;
    z-index: 1;
}
.slick-next {
    right: 6% !important;
    z-index: 1;
}
.slick-prev, .slick-next {
    font-size: 15px !important;
}
  
.slick-prev:before, .slick-next:before  {
    content: '' !important;
}

.icon {
    color: #fff;
    font-size: 60px;
    border-radius: 50px;
    padding: 5px;
    transition: 0.8s;
    
}

.icon:hover {
    background-color: #002042;
    opacity: 0.5;
    transition: 0.8s;
}

/*-------------------- Btn --------------------*/

.btn-plus {
    font-size: 24px;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    margin-left: 10px;
    transition: 0.8s;
    padding: 0px 8px;
}

.btn-plus:hover {
    transition: 0.8s;
    color: #3E546C;
    border: 1px solid #3E546C;
}

.btn-slider2 {
    text-decoration: none;
    border: 1px solid #3E546C;
    padding: 5px 10px;
    border-radius: 10px;
    color: #3E546C;
    font-weight: 600;
    transition: 1s;
    position: relative;
    bottom: 300px;
    margin-top: 0;
}

.btn-slider2:hover {
    transition: 1s;
    color: #fff;
    border: 1px solid #fff;
    background-color: #002042;
}

/*-------------------- Home3 --------------------*/

#home3 {
    width: 60%;
    margin: 0 auto;
    margin-top: 100px;
}

/*-------------------- Home3-info --------------------*/

.home3-info {
    margin-bottom: 60px;
}

/*-------------------- Slider2 --------------------*/

.slider2 {
    width: 100%;
    margin-bottom: 80px;
    height: 500px;
}

/*-------------------- Slider2-div --------------------*/

.slider2-div {
    height: 500px;
}

/*-------------------- Slider2-img --------------------*/

.slider2-img {
    height: 500px;
    width: 100%;
    opacity: 0.5;
}

/*-------------------- Slider2-title --------------------*/

.slider2-title {
    font-family: 'Fahkwang';
    font-size: 3em;
    color: #002042;
    font-weight: 500;
    letter-spacing: 2px;
    position: relative;
    bottom: 360px;
    width: 60%;
    margin: 0 auto;
}

/*-------------------- Slider2-text --------------------*/

.slider2-text {
    font-size: 1.4em;
    position: relative;
    bottom: 340px;
    color: #002042;
    width: 60%;
    margin: 0 auto;
}

/*-------------------- Media Queries --------------------*/

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
        
    /*-------------------- Slider --------------------*/
    .slick-prev {
        left: -1% !important;
        z-index: 1;
    }
    .slick-next {
        right: 15% !important;
        z-index: 1;
    }
    .slick-prev, .slick-next {
        font-size: 12px !important;
    }

    .slider-title {
        font-size: 1.4em;
    }

    /*-------------------- Slider2 --------------------*/
    #home3 {
        width: 100%;
    }
        
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /*-------------------- Slider --------------------*/
  .slick-prev {
    left: 4% !important;
    z-index: 1;
    }
    .slick-next {
        right: 10% !important;
        z-index: 1;
    }
    .slick-prev, .slick-next {
        font-size: 12px !important;
    }

    .slider-title {
        font-size: 1.4em;
    }

    /*-------------------- Slider2 --------------------*/
    #home3 {
        width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
       
    /*-------------------- Slider --------------------*/
    .slick-prev {
        left: 4% !important;
        z-index: 1;
    }
    .slick-next {
        right: 10% !important;
        z-index: 1;
    }
    .slick-prev, .slick-next {
        font-size: 12px !important;
    }

    .slider-title {
        font-size: 1.4em;
    }

    /*-------------------- Slider2 --------------------*/
    #home3 {
        width: 100%;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
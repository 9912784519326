/*-------------------- Dashboard --------------------*/

#dashboard {
    background-color: #fff;
    min-height: 100vh;
}

/*-------------------- Dashboard-container --------------------*/

.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-container h3 {
    font-family: 'Fahkwang';
    font-weight: 300;
    font-size: 1.6em;
    color: #002042;
    letter-spacing: 1px;
}

/*-------------------- Dashboard-header --------------------*/

.dashboard-header {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.dashboard-header span {
    padding: 20px;
}

/*-------------------- Dashboard-row --------------------*/

.dashboard-row {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #002042;
}

.dashboard-row p {
    font-size: 1.2em;
}

/*-------------------- Dashboard-actions --------------------*/

.dashboard-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

/*-------------------- Btn --------------------*/

.btn-delete,
.btn-see,
.btn-edit {
    padding: 5px;
    text-decoration: none;
    margin: 0 5px;
    border-radius: 10px;
    color: #002042;
    transition: 1s;
    border: 1px solid #002042;
}

.btn-see:hover,
.btn-edit:hover {
    color: #fff;
    background-color: #002042;
    transition: 1s;
    box-shadow: inset -5em 0 0 0 #002042, inset 5em 0 0 0 #002042;
}

.btn-delete {
    outline: none;
    font-family: 'Montserrat';
    font-size: 1em;
}

.btn-delete:hover {
    color: #fff;
    background-color: #D61600;
    transition: 1s;
    box-shadow: inset -5em 0 0 0 #D61600, inset 5em 0 0 0 #D61600;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .dashboard-row {
        width: 80%;
    }
   
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    
    .dashboard-row {
        width: 80%;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}


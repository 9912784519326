/*-------------------- Content-detail --------------------*/

#content-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 500px;
    margin: 20px 0; 
    transition: 1s;
}

#content-detail:hover {
    background-color: #5D6E81;
    transition: 1s;
    border-radius: 10px;
}

/*-------------------- content-letter-container --------------------*/

.content-letter-container {
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*-------------------- content-post-title --------------------*/

.content-post-title {
    font-family: 'Fahkwang';
    font-weight: 300;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 0px 20px;
    text-align: center;
    margin: 10px 0px;
}

/*-------------------- content-post-description --------------------*/

.content-post-description {
    text-align: center;
    font-size: 0.9em;
    padding: 0px 20px;
    margin: 0;
}

/*-------------------- content-post-author --------------------*/

.content-post-author {
    padding: 0px 10px;
    font-size: 0.8em;
    text-align: center;
}

/*-------------------- content-post-image --------------------*/

.content-post-image {
    width: 350px;
    max-height: 200px;
    padding: 10px 10px;
    cursor: pointer;
    transition: 1s;
}

.content-post-image:hover {
    zoom: 105%;
    transition: 1s;
}

/*-------------------- content-post-author --------------------*/

/*-------------------- Btn --------------------*/

.btn-read {
    text-decoration: none;
    font-size: 1em;
    font-weight: 600;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #002042;
    background-color: #fff;
    color: #002042;
    transition: 1s;
    margin: 10px 0px;
}

.btn-read:hover {
    box-shadow: inset -5em 0 0 0 #002042, inset 5em 0 0 0 #002042;
    background-color: #002042;
    color: #fff;
    transition: 1s;
}


/*-------------------- Post --------------------*/

#post {
    background-color: #fff;
    background: url(../../images/Biblioteca-cortada.jpg) repeat-x;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- Post-container --------------------*/

.post-container {
    margin: 40px 0px 10px 0px;
    background-color: #fff;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

/*-------------------- Container-border --------------------*/

.container-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- Post-container-info --------------------*/

.post-container-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 60vw;
    padding: 20px 20px 20px 20px;
}

/*-------------------- Post-container-info-data --------------------*/

.post-container-info-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/*-------------------- Post-container-img --------------------*/

.post-container-img {
    width: 100%;
    height: 250px;
}

/*-------------------- Post-image --------------------*/

.post-image {
    width: 60%;
}

/*-------------------- Post-title --------------------*/

.post-title {
    font-size: 3em;
    color: #002042;
    font-family: 'Fahkwang';
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
}

/*-------------------- Post-description --------------------*/

.post-description {
    font-size: 1.2em;
    font-weight: 600;
    color: #002042;
    text-align: center;
    width: 80%;
}

/*-------------------- Post-data --------------------*/

.post-data {
    color: #000;
    font-family: 'Fahkwang';;
    font-weight: 400;
    font-size: 1em;
}

/*-------------------- Post-content --------------------*/

.post-content {
    width: 80%;
    font-size: 1em;
    text-align: justify;
    white-space: pre-line;
    line-height: 1.6;
}

/*-------------------- Post-author --------------------*/

.post-author {
    font-family: 'Fahkwang';
    font-size: 1em;
}

/*-------------------- No-text-decoration --------------------*/

.no-text-decoration {
    text-decoration: none;
}
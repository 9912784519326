/*-------------------- Contact-detail --------------------*/

#contact-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #002042;
    border-radius: 10px;
    margin: 10px 0;
}

/*-------------------- Contact-detail-container --------------------*/

.contact-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.contact-detail-container p {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.contact-detail-container span {
    font-weight: 500;
    text-align: center;
}

/*-------------------- Post-message --------------------*/

.post-message {
    max-width: 400px;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    #contact-detail {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
/*-------------------- Header --------------------*/

header {
    width: 100%;
    background-color: transparent;
}

header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15vh;
    width: 80%;
    margin: auto;
}

header nav {
    display: flex;
}

header nav ul {
    display: flex;
}

header nav ul li {
    list-style: none;
}

/*-------------------- Navlink --------------------*/

.navlink {
    font-size: 1.2em;
    text-decoration: none;
    color: #fff;
    padding: 10px;
    margin: 0 10px;
    transition: 0.8s;
}

.navlink:hover {
    color: #002042;
    transition: 0.8s;
}

/*-------------------- Logo --------------------*/

.logo img {
    width: 150px;
    height: 150px;
}

/*-------------------- Btn --------------------*/

.btn-logout {
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 1.2em;
    margin: 0 10px;
    transition: 0.8s;
}

.btn-logout:hover {
    color: #002042;
    transition: 0.8s;
}

/*-------------------- Media Queries --------------------*/
@media (max-width: 630px) {
    .logo img {
        width: 120px;
        height: 120px;
    }
  
    .container {
        display: flex;
        flex-direction: row;
    }

    .form-group {
        display: block;
    }

}

@media (max-width: 1120px) {

    .menu-section nav {
        display: none;
    }

    .one,
    .two,
    .three {
        background-color: #ffffff;
        height: 5px;
        width: 100%;
        margin: 6px auto;
        transition-duration: 0.3s;
    }

    .menu-toggle {
        width: 40px;
        height: 30px;
        margin-right: 20px;
    }

    /* Fullscreen */
    .menu-section.on {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #1F3A57;
        z-index: 10; /* Camada acicma, evitar o scrool dos itens abaixo */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu-section.on nav {
        display: block;
    }

    .menu-section.on .menu-toggle {
        position: absolute;
        right: 25px;
        top: 25px;
    }

    .menu-section.on .menu-toggle .one {
        transform: rotate(45deg) translate(7px, 7px);
        background-color: #fff;;
    }

    .menu-section.on .menu-toggle .two {
        opacity: 0;
    }

    .menu-section.on .menu-toggle .three {
        transform: rotate(-45deg) translate(8px, -9px);
        background-color: #fff;
    }

    .menu-section.on nav ul {
        text-align: center;
        display: block;
    }

    .navlink {
        transition-duration: 0.5s;
        font-size: 2.0em;
        display: block;
        color: #fff;
        padding: 1.8em;
    }

    .btn-logout {
        transition-duration: 0.5s;
        font-size: 2.0em;
        display: block;
        color: #fff;
        padding: 1.8em 4.3em;
    }
}



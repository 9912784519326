/*-------------------- Footer Box --------------------*/

#footer-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #002042;
    color: #fff;
}

/*-------------------- Footer --------------------*/

#footer {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 20px;
}

/*-------------------- Footer Logo --------------------*/

.footer-logo {
    width: 305px;
    text-align: center;
    list-style: none;
    color: #fff;
}

.footer-logo h3 {
    font-family: 'Mansory', 'Montserrat';
    font-weight: 300;
    font-size: 1.5em;
    letter-spacing: 2px;
    margin-bottom: -5px;
}

.footer-logo p {
    font-family: 'Fahkwang', 'Montserrat';
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 4px;
}

/*-------------------- Footer Contacts --------------------*/

.footer-contacts {
    width: 305px;
    text-align: center;
    list-style: none;
    color: #fff;
}

.footer-contacts h4 {
    font-weight: normal;

}

/*-------------------- Footer Social --------------------*/

.footer-social {
    width: 305px;
    color: #fff;
    list-style: none;
    text-align: center;
    height: 135px;
}

.footer-social h4 {
    font-weight: normal;
}

/*-------------------- Footer Icons --------------------*/

.footer-icons {
    margin: 0px 5px 0px 5px;
    font-size: 35px;
    transition: 0.7s;
    color: #fff;
}

.footer-icons:hover {
    /*position: relative #001124 #7C8896;
    bottom: 10px;*/
    color: #D7D7D7;
    transition: 0.7s;
    /*box-shadow: 4px 4px #001124;*/
    box-shadow: 0 0 5px #001127, 0 0 20px #001127, 0 0 30px #001127, 0 0 200px #001127;
}

/*-------------------- Footer Copy --------------------*/

.footer-copy {
    width: 80%;
    text-align: center;
    display: flex;
    justify-content: center;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    #footer ul {
        flex-direction: column;
    }

    .footer-logo,
    .footer-contacts,
    .footer-social,
    .footer-copy {
        font-size: 1.6em;
    }

}

/*-------------------- Media Queries --------------------*/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
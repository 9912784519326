/*-------------------- Contact --------------------*/

#contact {
    background: url(../../images/Telefone\ Antigo-compreto90.jpg);
    width: 100%;
    height: 100vh;
}

/*-------------------- Contact-info-container --------------------*/

.contact-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 60%;
}


.contact-info-container h2 {
    text-transform: uppercase;
    font-family: 'Mansory';
    font-size: 4em;
    font-weight: 300;
    letter-spacing: 4px;
}

.contact-info-container p {
    font-family: 'Fahkwang';
    font-size: 2em;
    margin-top: 0;
    letter-spacing: 2px;
}


/*-------------------- Contact2 --------------------*/

#contact2 {
    background-color: #fff;
}

/*-------------------- Contact-container --------------------*/

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*-------------------- Contact-info --------------------*/

.contact-info {
    width: 40%;
    text-align: center;
    margin-top: 40px;
}

.contact-info h2 {
    color: #002042;
    font-weight: 600;
}

.contact-info p {
    color: #002042;
}

/*-------------------- Contact-form --------------------*/

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-form-label {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 40%;
}

.contact-form span {
    font-size: 0.9em;
    font-weight: 500;
    color: #002042;
}

.contact-form-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #1F3A57;
    padding: 10px;
    font-size: 1em;
}

.contact-form textarea {
    max-width: 100%;
    min-width: 100%;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1em;
    font-weight: 400;
}

/*-------------------- Form-info --------------------*/

.form-info {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
}

.form-info label {
    display: flex;
    flex-direction: column;
}

.form-info span {
    font-size: 1em;
    color: #000;
    margin-bottom: 5px;
    font-weight: 400;
}

.form-info-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #1F3A57;
    padding: 5px;
    font-size: 1em;
    font-weight: 400;
}

/*-------------------- Btn --------------------*/

.btn-submit {
    font-size: 1em;
    font-weight: 600;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #002042;
    background-color: #fff;
    color: #002042;
    transition: 1s;
    margin-bottom: 50px;
    margin-top: 10px;
}

.btn-submit:hover {
    box-shadow: inset -5em 0 0 0 #002042, inset 5em 0 0 0 #002042;
    background-color: #002042;
    color: #fff;
    transition: 1s;
}



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .contact-info {
        width: 80%;
    }

    .contact-form {
        width: 180%;
    }

    .contact-info-container {
        height: 60%;
        font-size: 1.2em;
        text-align: center;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}

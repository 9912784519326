/*-------------------- Panel --------------------*/

#panel {
    background-color: #fff;
    min-height: 100vh;
    height: 100%;
}

/*-------------------- Panel-container --------------------*/

.panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.panel-container h2 {
    font-family: 'Fahkwang';
    letter-spacing: 1px;
    font-size: 1.em;
    font-weight: 400;
    color: #002042;
}

/*-------------------- Panel-info --------------------*/

.panel-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- Panel-services --------------------*/

.panel-services {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*-------------------- Panel-services-box --------------------*/

.panel-services-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #002042;
    width: 400px;
    height: 400px;
    border-radius: 100%;
    margin-top: 30px;
}

.panel-services-box h3 {
    font-family: 'Fahkwang';
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: 2px;
    color: #002042;
}

.panel-services-box p {
    font-size: 1em;
    padding: 0px 40px;
    text-align: center;
}

/*-------------------- Btn --------------------*/

.btn-link {
    text-decoration: none;
    border: 1px solid #002042;
    padding: 10px 20px;
    border-radius: 10px;
    color: #002042;
    transition: 1s;
}

.btn-link:hover {
    box-shadow: inset -5em 0 0 0 #002042, inset 5em 0 0 0 #002042;
    background-color: #002042;
    color: #fff;
    transition: 1s;
}

/*-------------------- Icons --------------------*/

.create-content-icon {
    color: #000;
    font-size: 3em;
}

.dashboard-icon {
    color: #000;
    font-size: 3em;
}

.contact-icon {
    color: #000;
    font-size: 3em;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .panel-services {
        flex-wrap: wrap;
    }
   
    .margin-bottom {
        margin-bottom: 50px;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .panel-services {
        flex-wrap: wrap;
        justify-content: center;
    }
   
    .margin-bottom {
        margin-bottom: 50px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
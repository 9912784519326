/*-------------------- Loading --------------------*/

#loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

/*-------------------- Loading-name --------------------*/

.loading-name {
    text-align: center;
    color: #002042;
    font-size: 1.2em;
}

.loading-name h1 {
    font-family: 'Mansory', 'Montserrat';
    font-weight: normal;
    letter-spacing: 5px;
    margin-bottom: -10px;
}

.loading-name p {
    font-family: 'Fahkwang', 'Montserrat';
    font-weight: 300;
    letter-spacing: 10px;
}
/*-------------------- Login --------------------*/

#login {
    background-color: #fff;
    min-height: 100vh;
}

/*-------------------- Login-container --------------------*/

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*-------------------- Login-form --------------------*/

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.login-form-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #1F3A57;
    padding: 10px;
    font-size: 1em;
    width: 40vw;
}

.login-form span {
    font-size: 0.9em;
    font-weight: 500;
    color: #002042;
}

.login-form-label {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

/*-------------------- Login-info --------------------*/

.login-info {
    text-align: center;
}

.login-info h2 {
    color: #002042;
    font-weight: 600;
}

.login-info p {
    color: #002042;
}

/*-------------------- Error --------------------*/

.error {
    padding: 20px 50px;
    background-color: #9BA2AB;
    border-radius: 20px;
    color: #002042;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .login-info {
        width: 80%;
    }

    .login-form-input {
        width: 70vw;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    
    .login-info {
        width: 80%;
    }

    .login-form-input {
        width: 70vw;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
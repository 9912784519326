/*-------------------- Contact-forms --------------------*/

#contact-forms {
    background-color: #fff;
    min-height: 100vh;
    height: 100%;
}

/*-------------------- Contact-forms-container --------------------*/

.contact-forms-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*-------------------- Contact-forms-info --------------------*/

.contact-forms-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-forms-info h2 {
    font-family: 'Fahkwang';
    font-size: 1.6em;
    font-weight: 400;
    color: #002042;
    margin-bottom: 0;
}

/*-------------------- Contact-forms-content --------------------*/

.contact-forms-content {
    width: 60%;
    margin-top: 20px ;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .contact-forms-content {
        width: 80%;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .contact-forms-content {
        width: 80%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
/*-------------------- Editpost --------------------*/

#editpost {
    background-color: #fff;
}

/*-------------------- Editpost-container --------------------*/c

.editpost-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/*-------------------- Editpost-info--------------------*/

.editpost-info {
    text-align: center;
}

.editpost-info h2 {
    color: #002042;
    font-weight: 600;
}

.editpost-info p {
    color: #002042;
}


/*-------------------- Editpost-form --------------------*/

.editpost-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*-------------------- Editpost-form-label --------------------*/

.editpost-form-label {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 40vw;
}


/*-------------------- Editpost-form-span --------------------*/

.editpost-form-span {
    font-size: 0.9em;
    font-weight: 500;
    color: #002042;
}

/*-------------------- Editpost-form-input --------------------*/

.editpost-form-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #1F3A57;
    padding: 10px;
    font-size: 1em;
}

.editpost-form-input-textarea {
    max-width: 40vw;
    min-width: 40vw;
    min-height: 200px;
    font-family: 'Montserrat';
    font-size: 1em;
    border: 1px solid #1F3A57;
    outline: none;
    margin-top: 10px;
}

